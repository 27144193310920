import React from 'react';
import {
  Button,
  Header,
  HeroImage,
  LayoutUI,
  Stack,
  TextStyle
} from '../../ui-kit';

import Layout from '../../components/layout';
import Navbar from '../../components/navbar/navbar';
import MobileNavbar from '../../components/mobile-navbar/mobile-navbar';

import HeaderImage from '../../assets/images/get-involved.jpg';
import ROUTES from '../../utils/routes';
import './get-involved.scss';

import AmbassadorsIcon from '../../assets/icons/ambassadors.svg';
import CirclesIcon from '../../assets/icons/circles.svg';
import CoachingIcon from '../../assets/icons/message.svg';
import EducationIcon from '../../assets/icons/education.svg';
import OnlineCourseIcon from '../../assets/icons/seven-doors.svg';
import SpeakerSeriesIcon from '../../assets/icons/speaker-series.svg';

const CONTENT = {
  buttons: [
    {
      color: 'blue',
      icon: EducationIcon,
      text: 'Educational Curriculum',
      url: ROUTES.education()
    },
    {
      color: 'violet',
      icon: AmbassadorsIcon,
      text: 'Ambassadors',
      url: ROUTES.ambassadors()
    },
    {
      color: 'yellow',
      icon: OnlineCourseIcon,
      text: 'Online Course',
      url: ROUTES.onlineCourse()
    },
    {
      color: 'teal',
      icon: CoachingIcon,
      text: 'Coaching',
      url: ROUTES.coaching()
    },
    {
      color: 'red',
      icon: CirclesIcon,
      text: 'Circles',
      url: ROUTES.circles()
    },
    {
      color: 'orange',
      icon: SpeakerSeriesIcon,
      text: 'Live Events',
      url: ROUTES.liveEvents()
    }
  ]
};

const GetInvolved = () => (
  <Layout>
    <Navbar color="red" />
    <MobileNavbar />
    <main className="page-get-involved">
      <HeroImage image={HeaderImage}>
        <LayoutUI>
          <Stack distribution="center" vertical spacing="extraLoose">
            <div className="HeaderText">
              <Header>
                Take a quiz to find the perfect Project Happiness program for
                you.
              </Header>
            </div>

            <Button color="red" href={ROUTES.quiz()}>
              Take the quiz
            </Button>
          </Stack>
        </LayoutUI>
      </HeroImage>

      <LayoutUI>
        <div className="Programs">
          <Stack alignment="center" spacing="extraLoose" vertical>
            <TextStyle center>
              <Header>Project Happiness Programs</Header>
            </TextStyle>

            <div className="ProgramButtonContainer">
              {CONTENT.buttons.map(({ text, icon, color, url }) => {
                const colorStyles = `ProgramButton-${color}`;
                return (
                  <div className="ProgramButton" key={text}>
                    <a href={url}>
                      <div className={colorStyles}>
                        <div className="ProgramButtonIcon">
                          <img src={icon} alt="" />
                        </div>
                        <p className="ProgramButtonText">{text}</p>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </Stack>
        </div>
      </LayoutUI>
    </main>
  </Layout>
);

export default GetInvolved;
